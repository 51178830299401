import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Acceuil";
import Header from "./components/Header";
import About from "./pages/about/index";
import Contact from "./pages/contact/index";
import Projet from "./pages/projets/index";
import Services from "./pages/services/index"
import Footer from "./components/footer/index"
import Map from "./components/map";
import Mediatheque from "./pages/mediatheque/index"

export default function App() {
  return (
    <BrowserRouter>
    <Header />
      <Routes>
          <Route index element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="projet" element={<Projet />} />
          <Route path="contact" element={<Contact />} />
          <Route path="blog" element={<Services />} />
          <Route path="media" element={<Mediatheque />} />
          <Route path="map" element={<Map />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
