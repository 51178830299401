import "./index.css";

const index = () => {
  return (
    <div className="container">
      <h3 className="text-center parallax-title mt-5">
        <i>Nos projets</i>
      </h3>
      <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col">
          <div class="card">
            <img
              src="./images/dronek/EC2A3255.jpg"
              class="card-img-top"
              alt="Skyscrapers"
            />
            <div class="card-body">
              <h6 class="card-title text-success">
                <a href="/projet" className="text-decoration-none text-success">
                  <i>
                    Contribution à la reforestation dans la filière café cacao
                    (Phase 1)
                  </i>
                </a>
              </h6>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img
              src="./images/dronek/EC2A3253.jpg"
              class="card-img-top"
              alt="Los Angeles Skyscrapers"
            />
            <div class="card-body">
              <h6 class="card-title text-success">
                <a href="/projet" className="text-decoration-none text-success">
                  <i>
                    Contribution à la reforestation dans la filière café cacao
                    (Phase 2)
                  </i>
                </a>
              </h6>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img
              src="./images/dronek/EC2A3210.jpg"
              class="card-img-top"
              alt="Palm Springs Road"
            />
            <div class="card-body">
              <h6 class="card-title text-success">
                <a href="/projet" className="text-decoration-none text-success">
                  <i>
                    Contribution à la reforestation dans la filière café cacao
                    (Phase 3)
                  </i>
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a href="/projet" class="btn btn-success mb-5">
          Voir Plus
        </a>
      </div>
    </div>
  );
};

export default index;
