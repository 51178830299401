import React from "react";
import "./index.css";

export default function index() {
  return (
    <section className="speedyui speedyui-blog bg-light py-4 py-md-5">
      <div className="container">
        <div className="row  justify-content-center text-center">
          <div className="col-lg-8">
            <h3 className="text-center text-uppercase parallax-title">
              <i>Nos Dernières Actualités</i>
            </h3>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="card">
              
              <div className="card-body">
                <a
                  href="https://www.minutes-eco.com/news/2727-expertise-une-entreprise-ivoirienne-devoile-son-savoir-faire-en-agro-foresterie-et-en-agriculture-durable"
                  className="card-titre text-decoration-none d-block mb-2 text-uppercase"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontSize: 13 }}
                >
                  <i>
                    Expertise - Une entreprise ivoirienne devoile son
                    savoir-faire en agro-foresterie et en agriculture durable
                  </i>
                </a>
                <p className="d-flex icon-c card-titre align-items-center text-decoration-none">
                  <span className="icon d-inline-flex me-1">
                    <i className="icon-c fa fa-calendar" />
                  </span>{" "}
                  09 Octobre 2023
                </p>
                <p
                  className="card-text mt-1 text-black parallax-desc default-style"
                  style={{ fontSize: 13 }}
                >
                  L'entreprise ivoirienne Dronek, specialiste en foresterie,
                  agroforesterie et agriculture durable, a exposé son expertise
                  dans le cadre du Salon internationale de l'agriculture et des
                  ressources humaines qui s'est tenu du 29 septembre au samedi 8
                  octobre 2023, au parc d'exposition d'Abidjan.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card">
              
              <div className="card-body">
                <a
                  href="#!"
                  className="card-titre text-decoration-none d-block mb-2 text-uppercase"
                  style={{ fontSize: 13 }}
                >
                  <i>
                    SARA 2023 <br />
                    Elvis Yao Kouacou (Dg de Dronek) a présenté les avantages de
                    l’agroforesterie
                  </i>
                </a>
                <p className="d-flex icon-c card-titre align-items-center text-decoration-none">
                  <span className="icon d-inline-flex me-1">
                    <i className="icon-c fa fa-calendar" />
                  </span>{" "}
                  4 octobre 2023
                </p>
                <p
                  className="card-text mt-1 text-black parallax-desc default-style"
                  style={{ fontSize: 13 }}
                >
                  Salon international de l’agriculture et des ressources
                  animales tenu du 9 au 8 octobre 2023 au Parc des expositions
                  d’Abidjan à Port-Bouët, une entreprise ivoirienne spécialisée
                  en foresterie, agroforesterie et agriculture durable, Dronek,
                  a présenté certaines de ces missions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card">
              
              <div className="card-body">
                <a
                  href="https://www.linfodrome.com/societe/89110-restauration-du-couvert-forestier-une-structure-appuie-le-marathon-du-planting-d-arbres-forestiers-a-abengourou"
                  className="card-titre d-block text-decoration-none mb-2 text-uppercase"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontSize: 13 }}
                >
                  <i>
                    RESTAURATION DU COUVERT FORESTIER : UNE STRUCTURE APPUIE LE
                    "MARATHON DU PLANTING D’ARBRES FORESTIERS" À ABENGOUROU
                  </i>
                </a>
                <p className="d-flex icon-c card-titre align-items-center text-decoration-none">
                  <span className="icon d-inline-flex me-1">
                    <i className="icon-c fa fa-calendar" />
                  </span>{" "}
                  2 Août 2023
                </p>
                <p
                  className="card-text mt-1 text-black parallax-desc default-style"
                  style={{ fontSize: 13 }}
                >
                  L’entreprise d’agroforesterie Dronek a pris part, le 2 août
                  2023 à Abengourou (capitale de l’Indénié-Djuablin), à la
                  deuxième édition de l'opération « Marathon du planting
                  d’arbres forestiers » organisée par la direction générale du
                  ministère des Eaux et Forêts.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card">
              
              <div className="card-body">
                <a
                  href="https://afriksoir.net/gronek-pionnier-agriculture-durable-cote-divoire-elvis-kouacou-sia-2024/"
                  className="card-titre d-block text-decoration-none mb-2 text-uppercase"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontSize: 13 }}
                >
                  <i>
                    Dronek, pionnier de l'agriculture durable en Côte d'Ivoire :
                    Rencontre avec Elvis Kouacou au SIA 2024
                  </i>
                </a>
                <p className="d-flex icon-c card-titre align-items-center text-decoration-none">
                  <span className="icon d-inline-flex me-1">
                    <i className="icon-c fa fa-calendar" />
                  </span>{" "}
                  4 mars 2024
                </p>
                <p
                  className="card-text mt-1 text-black parallax-desc default-style"
                  style={{ fontSize: 13 }}
                >
                  Au Salon international de l'Agriculture de Paris (SIA) 2024,
                  Dronek a brillé par sa présence remarquée, mettant en lumière
                  son engagement sans faille en faveur de l'agriculture durable
                  en Côte d'Ivoire. Fondée par Livis Kouacou, un ingénieur
                  agronome visionnaire, l'entreprise s'est imposée comme un
                  acteur clé dans la promotion de pratiques agricoles
                  respectueuses de l'environnement, notamment dans le secteur
                  crucial du cacao.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
