import React from "react";
import Logo from "../Dronek-logo-g.png";
import "./index.css";

const Header = () => {
  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
          <a class="navbar-brand" href="/">
            <img src={Logo} alt="" width={150} />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active text-success fw-bold" aria-current="page" href="/">
                  {" "}
                  <i>ACCUEIL</i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-success" href="/about">
                  <i>À PROPOS</i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-success" href="/projet">
                  <i>RÉALISATIONS</i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-success" href="/blog">
                  <i>BLOG</i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-uppercase text-success" href="/media">
                  <i>Mediathèque</i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-success" href="/contact">
                  <i>CONTACT</i>
                </a>
              </li>
            </ul>
            {/* <div class="dropdown">
  <a class="btn btn-light dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
  <img src="images/translate/French.png" className='mx-1' width={25} alt="" />  FR
  </a>

  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">
    <img src="images/translate/Us.png" className='mx-1' width={25}  alt="" /> 
      EN
      </a>
      </li>
  </ul>
</div> */}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
