import React from "react";
import "./index.css";
import Partenaire from "../../components/partenaires/index";
import Team from "../../components/team/index";

function index() {
  return (
    <section>
      <div className="py-5 service-22">
        <div className="container">
          {/* Row */}
          <div className="row wrap-service-22">
            {/* Column */}
            <div className="col-lg-6">
              <img
                src="./images/1 (7).jpg"
                className="rounded-3 img-shadow rounded-5"
                alt="wrapkit"
                style={{ width: "100%", height: "350px" }}
              />
            </div>
            {/* Column */}
            <div className="col-lg-6 mt-5">
            <div class="parallax-desc default-style">
        <p>
        DRONEK SARL est un cabinet de conseil spécialisé en
                  agriculture, foresterie et agroforesterie. Avec DRONEK, vous
                  bénéficiez d'une expertise inégalée dans le domaine du
                  reboisement et de la restauration des écosystèmes. Nous sommes
                  fiers de collaborer avec des entreprises soucieuses de leur
                  empreinte environnementale, car nous croyons que la réussite
                  commerciale peut aller de pair avec la préservation de notre
                  planète. En choisissant DRONEK comme partenaire, vous
                  rejoignez une communauté d'entreprises engagées dans la
                  durabilité. Faites le choix de la qualité, de
                  l'écoresponsabilité et de l'engagement environnemental en
                  choisissant DRONEK comme fournisseur de plants pour votre
                  production. Ensemble, cultivons un avenir durable et prospère.
        </p>
      </div>
            </div>
            {/* Column */}
          </div>
        </div>
      </div>
      <h3 className="text-center parallax-title">
        <i>NOTRE VISION</i>
      </h3>
      <div class="parallax-desc default-style" style={{ fontSize: 18 }}>
        <p>
          Nous utilisons la technologie dans la pratique de l'agroforesterie en
          ayant recours aux drones pour la collecte d'informations relatives à
          la mise en place de systèmes agroforestiers de pointe.
        </p>
      </div>
      <Team />
      <Partenaire />
    </section>
  );
}

export default index;
