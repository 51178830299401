import React from "react";
import "./Acceuil.css";
import Slider from "../../components/slider/index";
import ProjectCard from "../projectCard/index";
import Team from "../../components/team/index";
import Counter from "../../components/counter/index";
import Partenaire from "../../components/partenaires/index";

export default function Acceuil() {
  return (
    <div>
      <Slider />
      <ProjectCard />
      <Counter />
      <div className="container">
      <h3 className="text-center parallax-title mt-5">
        <i>Participation SARA 2023</i>
      </h3>
  <div className="row justify-content-center">
    <div className="col-12">
      <video
        autoPlay
        muted
        controls={true}
        className="w-100" // This ensures the video fills the container width
      >
        <source
          src="./videos/video_2024-03-03_03-48-27.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  </div>
</div>

      <Team />
      <Partenaire  />
    </div>
  );
}
