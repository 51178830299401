import React from "react";
import "./index.css";

export default function index() {
  return (
<>
  {/* Team 1 - Bootstrap Brain Component */}
  <section className="bg-light mb-5">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h3 className="text-center parallax-title mt-5">
          <i>Notre équipe</i>
        </h3>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row gy-4 gy-lg-0 gx-xxl-5 justify-content-center">
      <div className="col-12 col-md-6 col-lg-4">
        <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
          <div className="card-body p-0">
            <figure className="m-0 p-0">
              <img
                className="img-fluid"
                loading="lazy"
                src="./images/sara/DSCF7036.jpg"
                alt="Flora Nyra"
              />
              <figcaption className="m-0 p-4">
                <h4 className="mb-1" style={{fontSize: 20}}>
                  Kouame Kouakou Donald
                </h4>
                <p className="text-secondary mb-0">Chef Projet</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4">
        <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
          <div className="card-body p-0">
            <figure className="m-0 p-0">
              <img
                className="img-fluid"
                loading="lazy"
                src="./images/sara/7D218386.jpg"
                alt="Evander Mac"
              />
              <figcaption className="m-0 p-4">
                <h4 className="mb-1" style={{fontSize: 20}}>Kambire Sié Paul</h4>
                <p className="text-secondary mb-0">Gérant</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4">
        <div className="card border-0 border-bottom border-primary shadow-sm overflow-hidden">
          <div className="card-body p-0">
            <figure className="m-0 p-0">
              <img
                className="img-fluid"
                loading="lazy"
                src="./images/sara/7D218409.jpg"
                alt="Taytum Elia"
              />
              <figcaption className="m-0 p-4">
                <h4 className="mb-1" style={{fontSize: 20}}>Kouacou Yao Elvis Franklin</h4>
                <p className="text-secondary mb-0">Fondateur-Expert Forestier</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</>
  );
}
