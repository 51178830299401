import React from "react";

function index() {
  return (
    <>
      <div className="container">
        <div className="row text-center mt-4">
          <h3 className="text-center fw-bold parallax-title mb-5">
            <i>Mediathèque</i>
          </h3>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modalsara1"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/sara/7D217149.jpg"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modalsara1"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/sara/7D217149.jpg" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modalsara2"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/sara/7D217301.png"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modalsara2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/sara/7D217301.png" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modalsara3"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/sara/7D217364.jpg"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modalsara3"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/sara/7D217364.jpg" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modalsara4"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/sara/7D217666.jpg"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modalsara4"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/sara/7D217666.jpg" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modaldronek1"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/dronek/DJI_0134.JPG"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modaldronek1"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/dronek/DJI_0134.JPG" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modaldronek2"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/dronek/DJI_0155.JPG"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modaldronek2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/dronek/DJI_0155.JPG" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modaldronek3"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/dronek/DJI_0167.JPG"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modaldronek3"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/dronek/DJI_0167.JPG" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modaldronek4"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/dronek/DJI_0160.JPG"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modaldronek4"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/dronek/DJI_0160.JPG" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modalformation1"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/dronek/WhatsApp Image 2023-12-18 à 02.44.47_e40f5278.jpg"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modalformation1"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/dronek/WhatsApp Image 2023-12-18 à 02.44.47_e40f5278.jpg" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modalformation2"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/dronek/WhatsApp Image 2023-12-18 à 02.44.52_ef59ad1d.jpg"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modalformation2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/dronek/WhatsApp Image 2023-12-18 à 02.44.52_ef59ad1d.jpg" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modalformation3"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/dronek/WhatsApp Image 2023-12-18 à 02.44.54_268270fb.jpg"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modalformation3"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/dronek/WhatsApp Image 2023-12-18 à 02.44.54_268270fb.jpg" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <a  
        data-bs-toggle="modal"
        href="#Modalformation4"
        role="button" className="d-block mb-4 h-100">
              <img
                className="img-fluid img-thumbnail"
                src="./images/dronek/WhatsApp Image 2023-12-18 à 02.44.49_e758ebc0.jpg"
                alt=""
              />
            </a>
            <div
        class="modal fade"
        id="Modalformation4"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="fs-5" id="exampleModalToggleLabel">
                Caption
              </h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{backgroundColor: "rgba(0, 0, 0, 0)"}}>
             <img src="./images/dronek/WhatsApp Image 2023-12-18 à 02.44.49_e758ebc0.jpg" alt="" style={{width: "100%"}} />
            </div>
          </div>
        </div>
      </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <h3 className="text-success fw-bold text-uppercase">
              <i>Institution Dronek</i>
            </h3>
            <video
              preload="auto"
              width="100%"
              loop
              controls={true}
              autoPlay={true}
              playsInline
              muted
            >
              <source
                src="./videos/video_2024-03-03_03-49-36.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="col-12 mt-3 col-md-6 mt-md-0">
            <h3 className="text-success fw-bold text-uppercase">
              <i>Participation SARA</i>
            </h3>
            <video
              preload="auto"
              width="100%"
              loop
              controls={true}
              autoPlay={true}
              playsInline
              muted
            >
              <source
                src="./videos/video_2024-03-03_03-48-27.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default index;
