import "./index.css";
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

function index() {
  const center = [5.354153, -4.003513];
  const zoom = 13;

  const markers = [{ position: [5.354153, -4.003513], text: "Bureau dronek" }];

  return (
    <div className="container">
      <div className="contact3 py-5">
        <div className="row no-gutters">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="card mt-4 border-0 mb-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      <div className="card-body d-flex align-items-center c-detail pl-0">
                        <div className="mr-3 align-self-center">
                          <img
                            src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon1.png"
                            alt="img-1"
                          />
                        </div>
                        <div className="mx-3">
                          <h3 className="text-success text-uppercase">
                            <i>Emplacement</i>
                          </h3>
                          <p className="text-success">
                            Cocody en face de l'entrée <br /> principale de
                            l’hôtel Palm Club
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="card-body d-flex align-items-center c-detail">
                        <div className="mr-3 align-self-center">
                          <img
                            src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon2.png"
                            alt="img"
                          />
                        </div>
                        <div className="mx-3">
                          <h3 className="text-success text-uppercase">
                            <i>Téléphone</i>
                          </h3>
                          <p className="">
                            <a
                              href="tel:+225 07 07 73 22 64"
                              className="text-decoration-none text-success"
                            >
                              <i>+225 07 07 73 22 64</i>
                            </a>
                          </p>
                          <p className="">
                            <a
                              href="tel:+225 27 21 51 41 49"
                              className="text-decoration-none text-success"
                            >
                              <i>+225 27 21 51 41 49</i>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="card-body d-flex align-items-center c-detail">
                        <div className="mr-3 align-self-center">
                          <img
                            src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon3.png"
                            alt="img"
                          />
                        </div>
                        <div className="mx-3">
                          <h3 className="text-success text-uppercase">
                            <i>Email</i>
                          </h3>
                          <p className="">
                            <a
                              href="mailto:info@dronek.net"
                              className="text-decoration-none text-success"
                            >
                              <i>info@dronek.net</i>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="card-shadow">
                  <img
                    src="./images/dronek/1696078047799.jpg"
                    alt="img"
                    className="img-fluid rounded-5"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-box ml-3">
                  <h3 className="parallax-title text-center text-success mt-2">
                    <i>FORMULAIRE DE CONTACTS</i>
                  </h3>
                  <p className="text-center">
                    Utiliser le Formulaire ci-après pour nous Contacter
                  </p>
                  <form className="mt-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mt-4">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Nom complet"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mt-4">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mt-4">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Téléphone"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mt-4">
                          <textarea
                            className="form-control"
                            rows={3}
                            placeholder="Entrez votre Message"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div class="text-end mt-4">
                          <button type="submit" class="btn btn-success mb-5">
                            Valider
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}
              <h3 className="parallax-title text-center text-success mt-5">
                <i>Emplacement</i>
              </h3>
              <div className="container">
                <MapContainer
                  center={center}
                  zoom={zoom}
                  style={{ height: "400px", width: "100%" }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />

                  {markers.map((marker, index) => (
                    <Marker key={index} position={marker.position}>
                      <Popup>{marker.text}</Popup>
                    </Marker>
                  ))}
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
