import React from "react";
import "./index.css";

const index = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src="images/logooo.png" alt="" className="img mb-3" />
            <p className="footer-text mt-3">
             <i className="fa fa-map-location-dot mx-2"></i>  
             <i>
             Cocody en face
              de l'entrée principale <br />
              de l’hôtel Palm Club
             </i>
</p>
            <p className="footer-text">
              <a
                href="tel:+225 07 07 73 22 64"
                className="text-decoration-none text-white"
              >
               <i className="fa fa-phone mx-2"></i>  <i>+225 07 07 73 22 64</i>
              </a>
            </p>
            <p className="footer-text">
              <a
                href="tel:+225 07 07 73 22 64"
                className="text-decoration-none text-white mt-2"
              >
              <i className="fa fa-home mx-2"></i>   <i>+225 27 21 51 41 49</i>
              </a>
            </p>
            <p className="footer-text">
            <a
                href="mailto:info@dronek.net"
                className="text-decoration-none text-white"
              >
             <i className="fa fa-envelope mx-2"></i>  <i>info@dronek.net</i>
              </a>
            </p>
          </div>
          <div className="col-md-6 mt-5">
            <div className="row">
              <div className="col-sm-3 col-4">
                <img
                  src="./images/sara/7D217149.jpg"
                  width={200}
                  className="img-thumbnail"
                  alt=""
                />
              </div>
              <div className="col-sm-3 col-4">
                <img
                  src="./images/sara/369175249_840356977790278_8406052615685505125_n.jpg"
                  width={200}
                  className="img-thumbnail"
                  alt=""
                />
              </div>
              <div className="col-sm-3 col-4">
                <img
                  src="./images/sara/7D218409.jpg"
                  width={200}
                  className="img-thumbnail"
                  alt=""
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-3 col-4">
                <img
                  src="./images/dronek/WhatsApp Image 2023-08-04 at 08.05.11.jpeg"
                  width={200}
                  className="img-thumbnail"
                  alt=""
                />
              </div>
              <div className="col-sm-3 col-4">
                <img
                  src="./images/dronek/EC2A3246.jpg"
                  width={200}
                  className="img-thumbnail"
                  alt=""
                />
              </div>
              <div className="col-sm-3 col-4">
                <img
                  src="./images/dronek/EC2A3255.jpg"
                  width={200}
                  className="img-thumbnail"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
            <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="text-white">
              Tous droits réservés © 2024 par
              <a
                className="text-white text-decoration-none text-uppercase"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <i>Dronek</i>
              </a>
              .
            </p>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li>
                <a
                  className="facebook"
                  href="https://www.facebook.com/Dronek.CI/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a
                  className="twitter"
                  href="mailto:info@dronek.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-solid fa-envelope" />
                </a>
              </li>
              <li>
                <a
                  className="linkedin"
                  href="https://www.linkedin.com/company/dronek "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 <i class="fa-brands fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default index;
