import React from "react";
import "./index.css";
import CountUp from "react-countup";
import Map from "../map/index";

const index = () => {
  return (
    <>
      {/* start count stats */}

<section id="counter-stats" class="wow fadeInRight" data-wow-duration="1.4s">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mb-3">
                <h3 class="fw-bolder text-white mt-3 text-center text-uppercase parallax-title">
                    <i>Zone d'interventions</i>
                </h3>
                <hr class="heading" />
                <Map />
            </div>
            <div class="col-md-6 mb-4">
                <h3 class="fw-bolder text-white mt-3 text-center parallax-title">
                    <i>BILAN D'ACTIVITES</i>
                </h3>
                <hr class="heading" />
                <div class="row">
                    <h3 className="text-center text-white">
                    AGROFORESTERIE
                    </h3>
                    <div class="col-sm-4 col-4 stats mt-3">
                        <i class="icon fa fa-tree" aria-hidden="true"></i>
                        <div class="counting text-white" data-count="280">
                            <CountUp class="title" isCounting start="0" end="10" duration="3.2"></CountUp>
                        </div>
                        <h6 class="text-white">Coopératives</h6>
                    </div>
                    <div class="col-sm-4 col-4  stats mt-3">
                        <i class="icon fa fa-user" aria-hidden="true"></i>
                        <div class="counting text-white" data-count="75">
                            <CountUp class="title" isCounting start="0" end="30" duration="3.2"></CountUp>
                        </div>
                        <h6 class="text-white">Producteurs</h6>
                    </div>
                    <div class="col-sm-4 col-4  stats mt-3">
                        <i class="icon fa fa-user" aria-hidden="true"></i>
                        <div class="counting text-white" data-count="75">
                            <CountUp class="title" isCounting start="0" end="40" duration="3.2"></CountUp>
                        </div>
                        <h6 class="text-white">Plants Produits</h6>
                    </div>
                </div>
                <div class="row mt-3">
                    <h3 className="text-center text-white">
                    REFORESTATION
                    </h3>
                    <div class="col-sm-4 col-4  stats mt-3">
                        <i class="icon fa fa-tree" aria-hidden="true"></i>
                        <div class="counting text-white" data-count="280">
                            <CountUp class="title" isCounting start="0" end="10" duration="3.2"></CountUp>
                        </div>
                        <h6 class="text-white">Superficie reforestée</h6>
                    </div>
                    <div class="col-sm-4 col-4  stats mt-3">
                        <i class="icon fa fa-user" aria-hidden="true"></i>
                        <div class="counting text-white" data-count="75">
                            <CountUp class="title" isCounting start="0" end="25" duration="3.2"></CountUp>
                        </div>
                        <h6 class="text-white">Jeunes sensibilisés</h6>
                    </div>
                    <div class="col-sm-4 col-4  stats mt-3">
                        <i class="icon fa fa-user" aria-hidden="true"></i>
                        <div class="counting text-white" data-count="75">
                            <CountUp class="title" isCounting start="0" end="30" duration="3.2"></CountUp>
                        </div>
                        <h6 class="text-white">Emplois créé</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div className="py-5">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-7 text-center me-2">
        <h3 className="fw-bold text-center parallax-title">
          <i>Nos Expertises</i>
        </h3>
        <div class="parallax-desc default-style" style={{ fontSize: 18 }}>
        <p>
        DRONEK est une société spécialisée en agriculture et en foresterie
            dont la mission principale est de veiller sur les ressources
            naturelles et lutter contre les menaces écologiques. Partenaires
            écoresponsable, nous nous engageons à réaliser des prestations dans
            le plus grand respect des normes écologiques.
        </p>
      </div>
      </div>
    </div>
    <div className="row justify-content-center text-center mt-5">
      <div className="col">
        <h4 className="text-success">
          <img
            src="./images/foret.svg"
            width={50}
            className="mx-2"
            alt=""
            srcSet=""
          />
          <i>FORESTERIE</i>
        </h4>
        <ul className="list list-style-none pl-0 mt-0">
          <li className="my-2">
            <p className="text-a mr-2">
              <i className="fa fa-check-circle text-success mx-1 fa-1x" />
              <i>
                AUDIT, RENFORCEMENT DES CAPACITÉS ET CONSEILS EN AGROÉCONOMIE
                POUR LES SOCIÉTÉS ET COOPÉRATIVES AGRICOLES
              </i>
            </p>
          </li>
          <li className="my-2">
            <p className="text-a mr-2">
              <i className="fa fa-check-circle text-success mx-1 fa-1x" />
              <i>APPUI À LA DIVERSIFICATION DES ACTIVITÉS AGRICOLES</i>
            </p>
          </li>
          <li className="my-2">
            <p className="text-a mr-2">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>FORMATION SUR LES TECHNIQUES D’ÉLEVAGE</i>
            </p>
          </li>
          <li>
            <p className="text-a">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>
                FORMATION DES PRODUCTEURS SUR LES BONNES PRATIQUES AGRICOLES
                (BPA)
              </i>
            </p>
          </li>
          <li>
            <p className="text-a">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>AMÉNAGEMENT DE PÉRIMÈTRES</i>
            </p>
          </li>
          <li>
            <p className="text-a">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>CARTOGRAPHIE DE L’OCCUPATION DU SOL</i>
            </p>
          </li>
          <li>
            <p className="text-a">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>CALCULS DE BIOMASSE</i>
            </p>
          </li>
          <li>
            <p className="text-a">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>FERTILISATION AZOTÉE PAR VOIE AÉRIENNE</i>
            </p>
          </li>
          <li>
            <p className="text-a">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>APPUI À A COMMERCIALISATION DES PRODUITS</i>
            </p>
          </li>
        </ul>
      </div>
      <div className="col">
        <img
          src="./images/dronek/WhatsApp Image 2023-04-11 at 10.17.32.jpeg"
          alt=""
          width={350}
          height={400}
          className="rounded-5"
        />
      </div>
      <div className="col">
        <h4 className="text-success">
          <img
            src="./images/agriculture.svg"
            width={50}
            className="mx-2"
            alt=""
            srcSet=""
          />
          <i>AGRICULTURE</i>
        </h4>
        <ul className="list list-style-none pl-0 mt-0">
          <li className="my-2">
            <p className="text-a mr-2">
              <i className="fa fa-check-circle text-success mx-1 fa-1x" />
              <i>
                FORMATIONS AUX MÉTIERS FORESTIERS (PÉPINIÉRISTES, SYLVICULTEURS,
                AMÉNAGISTES FORESTIERS)
              </i>
            </p>
          </li>
          <li className="my-2">
            <p className="text-a mr-2">
              <i className="fa fa-check-circle text-success mx-1 fa-1x" />
              <i>
                PRODUCTION DE PLANTS MARAICHERS PAR LA MISE EN PLACE DE
                PÉPINIÈRES
              </i>
            </p>
          </li>
          <li className="my-2">
            <p className="text-a mr-2">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>INVENTAIRE FORESTIER ET FAUNIQUE</i>
            </p>
          </li>
          <li>
            <p className="text-a">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>SUIVI DE REBOISEMENT</i>
            </p>
          </li>
          <li>
            <p className="text-a">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>PRODUCTION DE COMPOST</i>
            </p>
          </li>
          <li>
            <p className="text-a">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>TÉLÉDÉTECTIONS ET CARTOGRAPHIE FORESTIÈRE</i>
            </p>
          </li>
          <li>
            <p className="text-a">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>
                PRODUCTION DE PLANTS FORESTIERS ET FRUITIERS PAR LA MISE EN
                PLACE DE PÉPINIÈRES
              </i>
            </p>
          </li>
          <li>
            <p className="text-a">
              <i className="fa fa-check-circle text-success fa-1x mx-1" />
              <i>
                RENFORCEMENT DES CAPACITÉS DES ACTEURS SUR LES THÉMATIQUES DE
                GESTION DES FORÊTSE
              </i>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

      {/* end cont stats */}
    </>
  );
};

export default index;
