import React from "react";
import "./index.css";

export default function index() {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <h3 className="text-center parallax-title">
          <i>Nos projets</i>
        </h3>
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/EC2A3255.jpg"
              class="card-img-top"
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 120, marginTop: "-30px" }}
            >
              Prestataire unique
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>
                  Contribution à la reforestation dans la filière café cacao
                  (Phase 1)
                </i>
              </h6>
              <h6 className="badge titre-badge bg-success" style={{ fontSize: 10 }}>
                <i className="fa fa-calendar mx-2"></i>Janvier - Octobre 2021
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                Conseil Café Cacao, Côte d’Ivoire
              </p>
              <p class="card-text text-black-50">
                Production de 3 000 000 plants d’ombrage pour le compte des
                coopératives de la filière café cacao.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/DJI_0134.JPG"
              class="card-img-top"
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 120, marginTop: "-30px" }}
            >
              Prestataire unique
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>
                  Contribution à la reforestation dans la filière café cacao
                  (Phase 2)
                </i>
              </h6>
              <h6 className="badge titre-badge bg-success" style={{ fontSize: 10 }} >
                <i className="fa fa-calendar mx-2"></i>Janvier - Octobre 2022
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                Conseil Café Cacao, Côte d’Ivoire
              </p>
              <p class="card-text text-black-50">
                Production de 1 500 000 plants d’ombrage pour le compte des
                coopératives de la filière café cacao.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/EC2A3210.jpg"
              class="card-img-top"
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 120, marginTop: "-30px" }}
            >
              Prestataire unique
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>
                  Contribution à la reforestation dans la filière café cacao
                  (Phase 3)
                </i>
              </h6>
              <h6 className="badge titre-badge bg-success" style={{ fontSize: 10 }}>
                <i className="fa fa-calendar mx-2"></i>Janvier - Octobre 2022
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                Conseil Café Cacao, Côte d’Ivoire
              </p>
              <p class="card-text text-black-50">
                Production de 300 000 plants d’ombrage pour le compte des
                coopératives de la filière café cacao.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/plant a tree.jpg"
              class="card-img-top"
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 120, marginTop: "-30px" }}
            >
              Prestataire unique
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>
                  Production de plants d’avocatiers pour le compte des
                  coopératives partenaires à la SACO
                </i>
              </h6>
              <h6
                className="badge titre-badge bg-success"
                style={{ fontSize: 10 }}
              >
                <i className="fa fa-calendar mx-2"></i>Novembre 2021 - Mai 2022
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                Société Africaine de Cacao
              </p>
              <p class="card-text text-black-50">
                Production de 200 000 plants fruitiers pour le compte des
                coopératives partenaires à la SACO
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/WhatsApp Image 2023-08-04 at 08.05.12 (2).jpeg"
              class="card-img-top"
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 120, marginTop: "-30px" }}
            >
              Prestataire unique
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>Production, transport et planting d’arbres d’ombrages</i>
              </h6>
              <h6 className="badge titre-badge bg-success" style={{ fontSize: 10 }}>
                <i className="fa fa-calendar mx-2"></i>07 - 25 Octobre 2021
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                AGRO-MAP
              </p>
              <p class="card-text text-black-50">
                Production et fournitures des plants forestiers. Reboisement de
                20ha de terre dont 7ha en association avec des cultures
                vivrières et création d’une parcelle agroforestière
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/DSC00837.JPG"
              class="card-img-top"
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 120, marginTop: "-30px" }}
            >
              Prestataire unique
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>Planting et prise de coordonnées GPS</i>
              </h6>
              <h6 className="badge titre-badge bg-success" style={{ fontSize: 10 }}>
                <i className="fa fa-calendar mx-2"></i>Juin - Août 2022
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                Société Africaine de Cacao,Côte d’Ivoire
              </p>
              <p class="card-text text-black-50">
                Production, planting et prise de coordonnées GPS de 156 056
                plants d’arbres d’ombrages dans des parcelles de cacao des
                producteurs de coopératives affiliés à la SACO
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/EC2A3235.jpg"
              class="card-img-top"
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 120, marginTop: "-30px" }}
            >
              Prestataire unique
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>
                  Production et livraison plants d’arbres forestiers à des
                  coopératives partenaires d’OUTSPAN IVOIRE
                </i>
              </h6>
              <h6 className="badge titre-badge bg-success" style={{ fontSize: 10 }}>
                <i className="fa fa-calendar mx-2"></i>Juin - Août 2022
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                OLAM (OUTSPAN IVOIRE),Côte d’Ivoire
              </p>
              <p class="card-text text-black-50">
                Production et livraison de 108 006 plants d’arbres forestiers
                des sociétés coopératives partenaires d’OUTSPAN IVOIRE OLAM
                (OUTSPAN IVOIRE)
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/WhatsApp Image 2023-12-18 à 02.44.52_ef59ad1d.jpg"
              class="card-img-top"
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 125, fontSize: 10, marginTop: "-30px" }}
            > Formation Forest4Future
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>
                  FORMATION DES COOPÉRATIVES PARTENAIRES AU PROJET FOREST4FUTURE{" "}
                </i>
              </h6>
              <h6
                className="badge titre-badge bg-success"
                style={{ fontSize: 10 }}
              >
                <i className="fa fa-calendar mx-2"></i>
                Du 24 au 30 Novembre 2023
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                coopération allemande (GIZ)
              </p>
              <p class="card-text text-black-50">
                La formation s’est déroulée en deux phases soit une phase
                théorique incluant des formations via des méthodes andragogiques
                conçu par nos experts formateurs et une phase de démonstration
                pratique sur des parcelles agroforestières et des jachères.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/DSC00802.JPG"
              class="card-img-top"
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 120, fontSize: 10, marginTop: "-30px" }}
            >
              Membre du consortium
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>
                  Collecte de données de référence de l’agroforesterie et
                  dégradation des forêts
                </i>
              </h6>
              <h6
                className="badge titre-badge bg-success"
                style={{ fontSize: 10 }}
              >
                <i className="fa fa-calendar mx-2"></i>Septembre 2021- Mars 2022
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ)
              </p>
              <p class="card-text text-black-50">
                Collecte de données sur les mesures des arbres et calcul de
                biomasse
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/IFFN.jpg"
              class="card-img-top"
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 120, marginTop: "-30px" }}
            >
              Prestataire unique
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>Inventaire forestier et faunique national</i>
              </h6>
              <h6 className="badge titre-badge bg-success" style={{ fontSize: 10 }}>
                <i className="fa fa-calendar mx-2"></i>Janvier 2019 - Avril 2021
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                Office National de la Forêt, Côte d’Ivoire
              </p>
              <p class="card-text text-black-50">
                Inventaire des animaux sauvages sur le territoire national
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/agriculture.jpg"
              class="card-img-top"
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 120, marginTop: "-30px" }}
            >
              Prestataire unique
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>Fourniture de rejets de bananiers</i>
              </h6>
              <h6 className="badge titre-badge bg-success" style={{ fontSize: 10 }}>
                <i className="fa fa-calendar mx-2"></i> Juin 2022
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                Conseil Café Cacao, Côte d’Ivoire
              </p>
              <p class="card-text text-black-50">
                Fourniture de 35 839 rejets de bananiers
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card">
            <img
              src="./images/dronek/WhatsApp Image 2023-08-04 at 08.05.16.jpeg"
              class="card-img-top"
              height={202}
              alt="..."
            />
            <h6
              className="badge bg-success"
              style={{ width: 120, marginTop: "-30px" }}
            >
              Prestataire unique
            </h6>
            <div class="card-body">
              <h6 class="card-title text-success" style={{ fontSize: 12 }}>
                <i>Production, transport et planting d’arbres d’ombrages</i>
              </h6>
              <h6 className="badge titre-badge bg-success" style={{ fontSize: 10 }}>
                <i className="fa fa-calendar mx-2"></i>Juin - Août 2022
              </h6>
              <p className="text-success" style={{ fontSize: 12, width: 200 }}>
                Conseil Café Cacao, Côte d’Ivoire
              </p>
              <p class="card-text text-black-50">
                Fourniture et transport de 70 000 plants, reboisement et
                création de parcelle agroforestières Foncier Foresterie
                Agriculture (FOA)
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}
