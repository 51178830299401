import React from 'react'
import './index.css'

export default function index() {
  return (
    <section>
  <div className="container text-center mb-5">
    <div className="title">
     <h3 className="text-center fw-bold parallax-title">
        <i>
        DES PARTENAIRES QUI NOUS FONT CONFIANCE
        </i>
     </h3>
    </div>
    <div className="row" style={{ marginTop: 80 }}>
      <div className="col">
        <img
          src="./images/clients/agro-map-logo.png"
          alt=""
          className="img rounded"
          width={120}
        />
      </div>
      <div className="col">
        <img
          src="./images/clients/conseill-cafe-cacao-logo.png"
          alt=""
          className="img rounded"
          width={120}
        />
      </div>
      <div className="col">
        <img
          src="./images/clients/Olamlogo.png"
          alt=""
          className="img rounded"
          width={120}
        />
      </div>
      <div className="col">
        <img
          src="./images/clients/onf-international-logo.png"
          alt=""
          className="img rounded"
          width={120}
        />
      </div>
      <div className="col">
        <img
          src="./images/clients/saco-logo.jpg"
          alt=""
          className="img rounded"
          width={130}
          height={70}
        />
      </div>
      <div className="col">
        <img
          src="./images/clients/wcf-logo.png"
          alt=""
          className="img rounded"
          width={120}
        />
      </div>
    </div>
  </div>
</section>
  )
}
