import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

function Map() {
  const center = [ 7.546855, -5.5471];
  const zoom = 7.3;

  const markers = [
    { position: [6.816667000000001, -5.2833329999999705], text: 'Yamoussoukro' },
    { position: [6.6450,-4.6536], text: 'Bongouanou' },
    { position: [6.133332999999999,-5.933332999999948], text: 'Gagnoa' },
    { position: [6.87735000, -6.45022000], text: 'Daloa' },
  ];

  return (
    <MapContainer center={center} zoom={zoom} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {markers.map((marker, index) => (
        <Marker key={index} position={marker.position}>
          <Popup>{marker.text}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default Map;
