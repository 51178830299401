import React from "react";
import "./index.css";

const index = () => {
  return (
    <div>
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <section
              id="slider"
              class="hero-video min-vh-60 min-vh-md-100 with-header"
            >
              <div class="overlay"></div>
              <video loop autoPlay muted>
                <source
                  src="./videos/video_2024-03-03_03-49-36.mp4"
                  type="video/mp4"
                />
              </video>
            </section>
          </div>
          <div class="carousel-item">
            <section
              id="slider"
              class="hero-video min-vh-60 min-vh-md-100 with-header"
            >
              <div class="overlay"></div>
              <video preload autoPlay muted>
                <source src="./videos/video-forest.mp4" type="video/mp4" />
              </video>

              <div
                class="slider-caption dark slider-caption-center justify-content-center"
                style={{
                  transform: "translate3d(0px, 0px, 0px)",
                  opacity: "1",
                }}
              >
                <h3 data-animate="fadeInDown" class="fadeInDown animated">
                  <div class="standard-logo">
                    <img
                      src="./images/dronek-logo-icon-g.svg"
                      alt="Dronek Logo"
                      style={{
                        height: "200px",
                        marginTop: "50px",
                      }}
                    />
                  </div>
                </h3>
                <h3
                  style={{ fontSize: "3rem" }}
                  class="pt-4 mt-3 mb-5 justify-content-center"
                >
                  <span
                    data-rotate="fadeInDown"
                    data-speed="2000"
                    class="pyche"
                  >
                    {" "}
                    <i></i>{" "}
                  </span>
                </h3>
              </div>
              <div className="center justify-content-center">
                <div
                  class="zIndex one-page-arrow dark"
                  style={{ marginTop: 20 }}
                >
                  <i
                    class="bi bi-chevron-down infinite animated fadeInDown text-white"
                    style={{ fontSize: 30, marginTop: 100 }}
                  ></i>
                </div>
              </div>
            </section>
          </div>
          {/* <div class="carousel-item">
            <section class="hero-video min-vh-60 min-vh-md-100 with-header">
              <div class="overlay"></div>
              <img
                src="./images/dronek/EC2A3215.jpg"
                class="d-block w-100"
                alt="..."
              />
              <div class="container">
                <div
                  class="centered text-white fadeInDown"
                  style={{ fontSize: 70 }}
                >
                  Reforestation <br />
                   Afrique
                </div>
              </div>
            </section>
          </div>
          <div class="carousel-item">
            <section class="hero-video min-vh-60 min-vh-md-100 with-header">
              <div class="overlay"></div>
              <img
                src="./images/dronek/EC2A3210.jpg"
                class="d-block w-100"
                alt="..."
              />
              <div class="container">
                <div
                  class="centered text-white fadeInDown"
                  style={{ fontSize: 70 }}
                >
                  L'agriculture au coeur de la culture africaine
                </div>
              </div>
            </section>
          </div> */}
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default index;
